<template>
  <div class="lens-choose">
    <mt-cell
        title="Acuvue Oasys with Hydraclear® Plus"
        value=""
        @click.native="$emit('selmodel','')"
    >
        <span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="25" height="25" rx="12.5" fill="#059FE2"/>
<path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
</svg></span>
      <img slot="icon" src="../../assets/Oasys6.jpg" width="55" height="55" style="float: left;margin: 0px 12px 0 0;">
    </mt-cell>

    <div style="display: flex;flex-direction: row;justify-content: space-around;">
      <div style="width: 40%;">
        <h5 style="display: flex;justify-content: center">Сфера</h5>
        <mt-picker :slots="slotsDios" @change="onValuesChange"></mt-picker>
      </div>
      <div style="width: 40%;">
        <h5 style="display: flex;justify-content: center">Радиус</h5>
        <mt-picker :slots="slotsRadius" @change="onValuesChange"></mt-picker>
      </div>
    </div>
    <div>
      <mt-radio
          title="Упаковка"
          v-model="upakValue"
          :options="upakOptions">
      </mt-radio>
    </div>

  </div>
</template>
<style>
h5 {
  font-family: 'Pragmatica';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}
.mint-radiolist-title {
  font-family: 'Pragmatica';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}
.mint-cell {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 2px;

}
.lens-choose {
  background: #fff;
  height: 100%;
}
</style>
<script>
export default {
  name: "select-lenses-page",
  props:["model"],
  data: ()=>{
    return {
      slotsDios: [
        {
          flex: 1,
          values: [
            '+8.0',
            '+7.5',
            '+7.0',
            '+6.5',
            '+6.0',
            '+5.75',
            '+5.50',
            '+5.25',
            '+5.00',
            '+4.75',
            '+4.50',
            '+4.25',
            '+4.00',
            '+3.75',
            '+3.50',
            '+3.25',
            '+3.00',
            '+2.75',
            '+2.50',
            '+2.25',
            '+2.00',
            '+1.75',
            '+1.50',
            '+1.25',
            '+1.00',
            '+0.75',
            '+0.50',
            '-0.50',
            '-0.75',
            '-1.25',
            '-1.50',
            '-1.75',
            '-2.00',
            '-2.25',
            '-2.50',
            '-2.75',
            '-3.00',
            '-3.25',
            '-3.50',
            '-3.75',
            '-4.00',
            '-4.25',
            '-4.50',
            '-4.75',
            '-5.00',
            '-5.25',
            '-5.50',
            '-5.75',
            '-6.0',
            '-6.5',
            '-7.0',
            '-7.5',
            '-8.0',
            '-8.5',
            '-9.0',
            '-9.5',
            '-10.0',
            '-10.5',
            '-11.0',
            '-11.5',
            '-12.0',
          ],
          className: 'slot1',
          textAlign: 'center',
          defaultIndex: 27
        }
      ],
      slotsRadius: [
        {
          flex: 1,
          values: ['8.4', '8.6'],
          className: 'slot2',
          textAlign: 'center'
        }
      ],
      upakValue: '',
      upakOptions: [
        {
          label: '6 линз',
          value: '6',
        },
        {
          label: '12 линз',
          value: '12'
        },
        {
          label: '24 линзы',
          value: '24'
        }
      ]
    }
  },
  async mounted() {
    
  },
  methods: {
    async getData() {
    },
    async sendData() {

    },
    onValuesChange(picker, values) {
      if (values[0] > values[1]) {
        picker.setSlotValue(1, values[0]);
      }
    }
  },
  computed: {
    
  },
  components: {

  },
};
</script>

<template>
  <div class="home">
    <img src="../assets/byAcuvueBanner.png" alt="" style="width: 100%">

    <select-model-page @selmodel="curModel=$event" v-show="isSelectModel" />
    <select-lenses-page @selmodel="curModel=$event" :model="curModel" v-show="isSelectLense" />

  </div>
</template>
<style>
h2 {
  margin: 8px 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.list .mint-cell {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  margin: 8px 12px;
  min-height: 96px;
}

.mint-cell-wrapper {
  background-image: none!important;
}
.mint-cell-allow-right::after {
  border: solid 1px #069ED7!important;
  border-bottom-width: 0!important;
  border-left-width: 0!important;
  content: " ";
  top: 50%;
  right: 20px;
  position: absolute;
  width: 5px;
  height: 5px;
  transform: translateY(-50%) rotate(45deg);
}
.mint-cell-text {
  font-size: 16px;
  line-height: 19px;
}
.mint-cell-title {
  display: flex;
  align-items: center;
}
</style>
<script>
// import getInfo from "@/api/lenses";

import SelectModelPage from "@/views/Catalog/Models";
import SelectLensesPage from "@/views/Catalog/Lenses";
export default {
  name: "catalog-page",
  props:['curRoute'],
  data: ()=>{
    return {
      isSelectModel: true,
      isSelectLense: false,
      curModel: ''
    }
  },
  async mounted() {
    console.log('curRoute:')
    console.log(this.curRoute)
  },
  methods: {
    async getData() {
    },
    async sendData() {

    }
  },
  computed: {
    // showLoader: () => {
    //   return (this.isLoading)
    //       ? Indicator.open({text: 'Пожалуйста подождите, мы проверяем информацию.', spinnerType: 'snake'})
    //       : Indicator.close()
    // }
  },
  watch: {
    'curModel' : function () {
      if (this.curModel) {
        this.isSelectLense=true
        this.isSelectModel=false
      }
      if (!this.curModel) {
        this.isSelectLense=false
        this.isSelectModel=true
      }
    }
  },
  components: {
    SelectLensesPage,
    SelectModelPage

  },
};
</script>

<template>
  <div class="list">
    <h2 @click="getData">Выберите ваши линзы:</h2>
    <mt-cell
        title="Acuvue Oasys with Hydraclear® Plus"
        value=""
        @click.native="$emit('selmodel','oasys6')"
    >
        <span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="25" height="25" rx="12.5" fill="#059FE2"/>
<path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
</svg></span>
      <img slot="icon" src="../../assets/Oasys6.jpg" width="55" height="55" style="float: left;margin: 0px 12px 0 0;">
    </mt-cell>
    <mt-cell
        title="Acuvue Oasys with Hydraclear® Plus for Astigmatism"
        value=""
        @click.native="$emit('selmodel','oasys6asti')"
    >
        <span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="25" height="25" rx="12.5" fill="#059FE2"/>
<path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
</svg></span>
      <img slot="icon" src="../../assets/Oasys6asti.jpg" width="55" height="55" style="float: left;margin: 0px 12px 0 0;">
    </mt-cell>
    <mt-cell
        title="Acuvue 2"
        value=""
        @click.native="$emit('selmodel','acuvue2')">
        <span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="25" height="25" rx="12.5" fill="#059FE2"/>
<path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
</svg></span>
      <img slot="icon" src="../../assets/acuvue2.jpg" width="55" height="55" style="float: left;margin: 0px 12px 0 0;">
    </mt-cell>
    <mt-cell
        title="Acuvue Oasys with Transitions™"
        value=""
        @click.native="$emit('selmodel','transition')">
        <span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="25" height="25" rx="12.5" fill="#059FE2"/>
<path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
</svg></span>
      <img slot="icon" src="../../assets/transition.jpg" width="55" height="55" style="float: left;margin: 0px 12px 0 0;">
    </mt-cell>
    <mt-cell
        title="Acuvue Oasys 1-Day with Hydraluxe™"
        value=""
        @click.native="$emit('selmodel','1day30')">
        <span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="25" height="25" rx="12.5" fill="#059FE2"/>
<path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
</svg></span>
      <img slot="icon" src="../../assets/1day30.jpg" width="55" height="55" style="float: left;margin: 0px 12px 0 0;">
    </mt-cell>
    <mt-cell
        title="Acuvue Oasys 1-Day with Hydraluxe™ for Astigmatism"
        value=""
        @click.native="$emit('selmodel','1day30asti')">
        <span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="25" height="25" rx="12.5" fill="#059FE2"/>
<path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
</svg></span>
      <img slot="icon" src="../../assets/1day30asti.jpg" width="55" height="55" style="float: left;margin: 0px 12px 0 0;">
    </mt-cell>
    <mt-cell
        title="1-Day Acuvue TruEye™"
        value=""
        @click.native="$emit('selmodel','trueye30')">
        <span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="25" height="25" rx="12.5" fill="#059FE2"/>
<path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
</svg></span>
      <img slot="icon" src="../../assets/trueye30.jpg" width="55" height="55" style="float: left;margin: 0px 12px 0 0;">
    </mt-cell>
    <mt-cell
        title="1-Day Acuvue Moist®"
        value=""
        @click.native="$emit('selmodel','moist30')">
        <span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="25" height="25" rx="12.5" fill="#059FE2"/>
<path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
</svg></span>
      <img slot="icon" src="../../assets/moist30.jpg" width="55" height="55" style="float: left;margin: 0px 12px 0 0;">
    </mt-cell>
    <mt-cell
        title="1-Day Acuvue Moist® Multifocal"
        value=""
        @click.native="$emit('selmodel','moist30multi')">
        <span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="25" height="25" rx="12.5" fill="#059FE2"/>
<path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
</svg></span>
      <img slot="icon" src="../../assets/moist30multi.jpg" width="55" height="55" style="float: left;margin: 0px 12px 0 0;">
    </mt-cell>
    <mt-cell
        title="1-Day Acuvue Moist® for Astigmatism"
        value=""
        @click.native="$emit('selmodel','moist30asti')">
        <span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="25" height="25" rx="12.5" fill="#059FE2"/>
<path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
</svg></span>
      <img slot="icon" src="../../assets/moist30asti.jpg" width="55" height="55" style="float: left;margin: 0px 12px 0 0;">
    </mt-cell>
  </div>
</template> 
<script>
export default {
  name: "select-model-page",
  props:[],
  data: ()=>{
    return {
      
    }
  },
  async mounted() {
    
  },
  methods: {
    async getData() {
      console.log('GData')
    },
    async sendData() {

    }
  },
  computed: {
    
  },
  components: {

  },
};
</script>
